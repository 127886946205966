import { PanelPlugin } from '@grafana/data';
import { IPanelOptions } from './types';
import MainPanel from './index';

export const plugin = new PanelPlugin<IPanelOptions>(MainPanel).setPanelOptions((builder) => {
  return builder.addTextInput({
    path: 'baseImageUrl',
    name: 'Base image url',
  });
});
