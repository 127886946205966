import React, { useRef, useEffect, useMemo } from 'react';
import h337 from 'heatmap.js';
import { css } from '@emotion/css';
import { useStyles2 } from '@grafana/ui';
import { GrafanaTheme2 } from '@grafana/data';
import { IPoint } from '../../interfaces/map';

interface IMapProps {
  mapImage?: string;
  data?: IPoint[];
  maxX?: number;
  maxY?: number;
  maxValue?: number;
  eventBut?: any;
  onLoadImage: () => void;
}

const Map: React.FC<IMapProps> = (props) => {
  const styles = useStyles2(getStyles);
  const mapContainerRef = useRef(null);
  const heatmapInstanceRef = useRef(null);

  useEffect(() => {
    let heatmapInstance: any = heatmapInstanceRef.current;

    if (!heatmapInstanceRef.current) {
      heatmapInstance = heatmapInstanceRef.current = h337.create({
        container: mapContainerRef.current,
        radius: 2,
        minOpacity: 0,
        maxOpacity: 0.65,
        // gradient: {
        //   // enter n keys between 0 and 1 here
        //   // for gradient color customization
        //   '.25': 'dodgerblue',
        //   '.5': 'magenta',
        //   '.75': 'gold',
        //   '.9': 'red',
        // },
      });
    }

    heatmapInstance?.setData({ max: props?.maxValue, data: props?.data || [] });

    // const data = {
    //   data: [
    //     { x: 1, y: 1, value: 2 },
    //     { x: 10, y: 10, value: 3 },
    //     { x: 11, y: 12, value: 4 },
    //     { x: 800, y: 22, value: 1 }
    //   ],
    // };
    // heatmapInstance?.setData(data);
  }, [props?.data, props?.maxValue]);

  const containerStyle = useMemo(() => {
    if (props?.maxX && props?.maxY) {
      return { width: props.maxX, height: props?.maxY };
    }
    return {};
  }, [props?.maxX, props?.maxY]);

  return (
    <div className={styles.container} data-testid="map-container">
      <div
        ref={mapContainerRef}
        className={styles.mapContainer}
        style={containerStyle}
        data-testid="heatmap-container"
      />
      <img
        src={props?.mapImage}
        className={styles.imgContainer}
        id="map-image"
        data-testid="map-image"
        onLoad={props.onLoadImage}
        style={containerStyle}
      />
    </div>
  );
};

const getStyles = (theme: GrafanaTheme2) => {
  return {
    container: css`
      width: 100%;
      height: 100%;
      position: relative;
    `,
    mapContainer: css`
      position: absolute !important;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 2;
    `,
    imgContainer: css`
      z-index: 1;
    `,
  };
};

export default Map;
