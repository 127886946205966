import React, { useRef, useEffect } from 'react';
import { css } from '@emotion/css';
import { GrafanaTheme2 } from '@grafana/data';
import { useStyles2 } from '@grafana/ui';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import { IPoint } from 'interfaces/map';
import Map from '../map';

interface IMapData {
  point: IPoint[];
  maxX: number;
  maxY: number;
  maxValue: number;
}

interface IMapLayoutProps {
  // children: any;
  width: number;
  height: number;
  mapImage?: string;
  mapData: IMapData;
  eventBus?: any;
}

const MapLayout: React.FC<IMapLayoutProps> = ({ width, height, mapImage, mapData, eventBus }) => {
  const transformRef = useRef<any>(null);
  const styles = useStyles2(getStyles);

  useEffect(() => {
    // zoom to element when user change time or refresh
    eventBus?.on('refresh', () => {
      setTimeout(() => {
        onLoadImage();
      }, 500);
    });
    return () => {
      eventBus?.off('refresh', onLoadImage);
    };
  }, []);

  const onLoadImage = () => {
    const mapImageElement = document.getElementById('map-image');

    if (mapImageElement) {
      const containerWidth = mapImageElement?.offsetWidth;
      const containerHeight = mapImageElement?.offsetHeight;
      const scaleX = width / containerWidth;
      const scaleY = height / containerHeight;
      if (transformRef.current && !isNaN(scaleX) && !isNaN(scaleY)) {
        const scale = Math.min(scaleX, scaleY);
        transformRef.current.zoomToElement(mapImageElement, scale, 500);
      }
    }
  };

  return (
    <div style={{ width, height }}>
      <TransformWrapper
        ref={transformRef}
        initialScale={1}
        maxScale={100}
        minScale={0.1}
        limitToBounds={false}
        centerOnInit={true}
      >
        {({ zoomIn, zoomOut }) => (
          <>
            <div className={styles.zoomBox}>
              <button onClick={() => zoomIn()} className={styles.zoomInButton}>
                +
              </button>
              <button onClick={() => zoomOut()} className={styles.zoomOutButton}>
                -
              </button>
            </div>
            <TransformComponent>
              <div style={{ width, height }}>
                <Map
                  mapImage={mapImage}
                  data={mapData?.point}
                  maxX={mapData?.maxX}
                  maxY={mapData?.maxY}
                  maxValue={mapData?.maxValue}
                  onLoadImage={onLoadImage}
                />
              </div>
            </TransformComponent>
          </>
        )}
      </TransformWrapper>
    </div>
  );
};

const getStyles = (theme: GrafanaTheme2) => {
  return {
    zoomBox: css`
      position: absolute;
      right: 20px;
      bottom: 20px;
      z-index: 2;
      display: flex;
      flex-direction: column;
    `,
    zoomInButton: css`
      border: 1px solid #bdb5b5;
      border-bottom: 0px;
    `,
    zoomOutButton: css`
      border: 1px solid #bdb5b5;
    `,
  };
};

export default MapLayout;
