import React, { useMemo } from 'react';
import { EmptySearchResult } from '@grafana/ui';

import { IPanelPageProps } from './types';
import { getVariableValue } from './utils/config';
import { convertToFloat, convertToInt } from './utils/number';
import { IPoint } from './interfaces/map';

import MapLayout from './components/mapLayout';

interface IMainPanelProps extends IPanelPageProps {}

const MainPanel: React.FC<IMainPanelProps> = (props) => {
  const mapImage = useMemo(() => {
    const store = getVariableValue('store');
    const baseImageUrl = props?.options?.baseImageUrl;
    const storeImageData = props?.data?.series.find((series) => series?.refId === 'store_image');
    const imageName = storeImageData?.fields?.[0]?.values?.[0] || '';
    return imageName ? `${baseImageUrl}/stores/${store}/image/${imageName}` : '';
  }, [props?.data, props?.options]);

  const mapData = useMemo(() => {
    const point: IPoint[] = [];
    let maxX = 0,
      maxY = 0,
      maxValue = 0;

    props?.data?.series?.forEach((seriesData) => {
      if (seriesData.refId === 'store_data') {
        const coordinateData = seriesData?.fields?.find((field) => field?.name === 'coordinate');
        const labels = coordinateData?.labels || {};
        const pointX = convertToInt(labels?.x);
        const pointY = convertToInt(labels?.y);

        if (!maxX || !maxY) {
          maxX = convertToInt(labels?.maxX) || 0;
          maxY = convertToInt(labels?.maxY) || 0;
        }

        if (pointX !== null && pointY !== null) {
          coordinateData?.values?.forEach((value) => {
            const pointValue = convertToFloat(value);
            if (pointValue !== null) {
              point.push({
                x: pointX,
                y: pointY,
                value: pointValue,
              });
            }

            if (pointValue && pointValue > maxValue) {
              maxValue = pointValue;
            }
          });
        }
      }
    });

    return { point, maxX, maxY, maxValue };
  }, [props?.data]);

  return mapImage ? (
    <MapLayout
      width={props.width}
      height={props.height}
      mapImage={mapImage}
      mapData={mapData}
      eventBus={props?.eventBus}
    />
  ) : (
    <EmptySearchResult>Plan image not found</EmptySearchResult>
  );
};

export default MainPanel;
